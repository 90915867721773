
import { renderRichText } from "@storyblok/nuxt-2"
import richTextSchema from "@/helpers/storyblokRichTextSchema"

export default {
    name: "RichTextBlok",
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        hasDesktopText() {
            return !!this.blok.enableDesktopOverride
        },
    },
    methods: {
        getContent(text) {
            return renderRichText(text, {
                schema: richTextSchema,
            })
        },
    },
}
